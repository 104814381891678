<template>
  <div class="comparison-container">
    <div
      v-for="index in amountOfComparisons"
      :key="`totalPurchases-comparison-${index - 1}`"
      :set="(item = getComparisonItem(rowData, index))"
      :style="{ width: getWidth(SUPPLIER_PRODUCTS_TABLE_HEADERS.TOTAL_PURCHASES) }"
    >
      <PriceWithPercentage
        v-if="item"
        :price="item.sumPrice ? item.sumPrice : 0"
        :percentage="calculatePercentage(item.sumPrice, sumPurchases[index - 1])"
        :percentage-tool-tip-text="$t('terms.supplierTerms.supplierTermTable.percentageFromBasket')"
      />
      <p v-else>{{ MISSING_DATA_TEXT }}</p>
    </div>
  </div>
</template>
<script>
import PriceWithPercentage from '../../PriceWithPercentage';
import { SUPPLIER_PRODUCTS_TABLE_HEADERS } from '../supplierProductsTableConstants';
import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';

export default {
  name: 'TotalPurchasesComparisonCell',
  components: { PriceWithPercentage },
  props: {
    rowData: { type: Object, default: null },
    amountOfComparisons: { type: Number, required: true },
    calculatePercentage: { type: Function, required: true },
    getComparisonItem: { type: Function, required: true },
    getWidth: { type: Function, required: true },
    sumPurchases: { type: Array, required: true },
  },
  setup() {
    return { SUPPLIER_PRODUCTS_TABLE_HEADERS, MISSING_DATA_TEXT };
  },
};
</script>
<style lang="scss" scoped>
.comparison-container {
  display: flex;
}
</style>
