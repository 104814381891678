import AmountOfAssociatedCell from './AmountOfAssociatedCell.vue';
import AnnualImpactCell from './AnnualImpactCell.vue';
import AveragePeriodPriceCell from './AveragePeriodPriceCell.vue';
import FormattedParagraphComparisonCell from './FormattedParagraphComparisonCell.vue';
import FormattedParagraphCell from './FormattedParagraphCell.vue';
import PreviousPeriodDifferenceCell from './PreviousPeriodDifferenceCell.vue';
import PurchaseListCell from './PurchaseListCell.vue';
import ProductNameCell from './ProductNameCell.vue';
import SimulationPriceCell from './SimulationPriceCell.vue';
import SkuCell from './SkuCell.vue';
import TotalPurchasesComparisonCell from './TotalPurchasesComparisonCell.vue';
import DiscountCell from '../../DiscountCell.vue';

const cellRenderersComponentNames = {
  amountOfAssociated: AmountOfAssociatedCell.name,
  annualImpact: AnnualImpactCell.name,
  averagePeriodPrice: AveragePeriodPriceCell.name,
  formattedParagraphComparison: FormattedParagraphComparisonCell.name,
  formattedParagraph: FormattedParagraphCell.name,
  previousPeriodDifference: PreviousPeriodDifferenceCell.name,
  productName: ProductNameCell.name,
  simulationPrice: SimulationPriceCell.name,
  sku: SkuCell.name,
  totalPurchasesComparison: TotalPurchasesComparisonCell.name,
  discount: DiscountCell.name,
  purchaseList: PurchaseListCell.name,
};

export {
  cellRenderersComponentNames,
  AmountOfAssociatedCell,
  AnnualImpactCell,
  AveragePeriodPriceCell,
  FormattedParagraphComparisonCell,
  FormattedParagraphCell,
  PreviousPeriodDifferenceCell,
  ProductNameCell,
  SimulationPriceCell,
  SkuCell,
  TotalPurchasesComparisonCell,
  DiscountCell,
  PurchaseListCell,
};
