<template>
  <SimulationPopover
    :is-open="selectedSimulationId === productId"
    :min-date-price="minDateOrderItem ? minDateOrderItem.netAmountPerUnit : 0"
    :max-date-price="maxDateOrderItem ? maxDateOrderItem.netAmountPerUnit : 0"
    :min-price="minPriceOrderItem ? minPriceOrderItem.netAmountPerUnit : 0"
    :max-price="maxPriceOrderItem ? maxPriceOrderItem.netAmountPerUnit : 0"
    :previous-average-price="previousAveragePrice ? previousAveragePrice : 0"
    :simulation-mode="simulationMode"
    :is-sim-active="simulatedPrice !== null"
    @on-apply="(args) => onApplySimulation(productId, args)"
    @on-toggle-popover="onTogglePopover"
  >
    <div
      :set="(percentageChange = calculatePercentageChangeInPrice(simulatedPrice, pricedOrderItemAverage))"
      class="simulated-price"
    >
      <div class="editable-price-container" @click.stop @click="onUpdateSimulationProduct(productId)">
        <el-tooltip
          v-if="simulatedPrice"
          placement="top-end"
          effect="dark"
          :content="$t(getLabelBySimulationMode(simulationMode))"
        >
          <SimulationPriceIcon v-if="simulationMode === PRICE_MODE_NAMES.CUSTOM" :size="16" />
          <SimulationPreviousAverageIcon
            v-else-if="simulationMode === PRICE_MODE_NAMES.PREV_PERIOD_AVG_PRICE"
            :size="16"
          />
          <SimulationArrowIcon v-else :class="getClassBySimulationMode(simulationMode)" />
        </el-tooltip>
        <p
          class="editable-price"
          :class="{ ['inactive']: !simulatedPrice, ['underline']: selectedSimulationId === productId }"
        >
          {{
            simulatedPrice
              ? formatMoney(simulatedPrice)
              : $t('terms.supplierTerms.supplierTermTable.simulation.inactive')
          }}
        </p>
        <SimulationTableDropdownArrowIcon :size="16" />
      </div>
      <el-tooltip v-if="percentageChange !== null" placement="top" effect="dark">
        <PercentageChange :percentage-change="percentageChange" />
        <div slot="content" class="percentage-change-tooltip">
          {{ $t('terms.supplierTerms.supplierTermTable.simulation.simPercentageChange') }}
        </div>
      </el-tooltip>
    </div>
  </SimulationPopover>
</template>
<script>
import { computed } from 'vue';

import { useCurrency } from '@/locale/useCurrency';
import {
  SimulationArrowIcon,
  SimulationTableDropdownArrowIcon,
  SimulationPreviousAverageIcon,
  SimulationPriceIcon,
} from '@/assets/icons';

import { calculatePercentageChangeInPrice } from '../supplierProductsTable';
import SimulationPopover from '../SimulationPopover';
import { PRICE_MODE_NAMES, priceModes } from '../supplierProductsTableConstants';

import { formatCurrencyOrDash } from '../../../purchaseManagementFormatters';
import PercentageChange from '../../PercentageChange';
import { MISSING_DATA_TEXT } from '../../../tools/constants';

export default {
  name: 'SimulationPriceCell',
  components: {
    SimulationPriceIcon,
    SimulationArrowIcon,
    SimulationTableDropdownArrowIcon,
    SimulationPreviousAverageIcon,
    SimulationPopover,
    PercentageChange,
  },
  props: {
    rowData: { type: Object, default: null },
    selectedSimulationId: { type: String, default: null },
  },
  emits: ['on-toggle-popover', 'on-apply', 'on-update-simulation-product'],
  setup(props, { emit }) {
    const { currencyFormat } = useCurrency();

    const getClassBySimulationMode = (simulationMode) => {
      return priceModes.find((priceMode) => priceMode.name === simulationMode)?.arrowDir ?? 'up';
    };

    const getLabelBySimulationMode = (simulationMode) => {
      return priceModes.find((priceMode) => priceMode.name === simulationMode)?.label ?? MISSING_DATA_TEXT;
    };

    const onTogglePopover = () => {
      emit('on-toggle-popover');
    };

    const onApplySimulation = (id, args) => {
      emit('on-apply', { id, args });
    };

    const onUpdateSimulationProduct = (productId) => {
      emit('on-update-simulation-product', productId);
    };

    return {
      simulatedPrice: computed(() => props.rowData?.simulatedPrice),
      simulationMode: computed(() => props.rowData?.simulationMode),
      minDateOrderItem: computed(() => props.rowData?.minDateOrderItem),
      maxDateOrderItem: computed(() => props.rowData?.maxDateOrderItem),
      maxPriceOrderItem: computed(() => props.rowData?.maxPriceOrderItem),
      minPriceOrderItem: computed(() => props.rowData?.minPriceOrderItem),
      pricedOrderItemAverage: computed(() => props.rowData?.pricedOrderItemAverage),
      previousAveragePrice: computed(() => props.rowData?.previousAveragePrice),
      productId: computed(() => props.rowData?.product.id),
      PRICE_MODE_NAMES,
      onTogglePopover,
      onApplySimulation,
      onUpdateSimulationProduct,
      getLabelBySimulationMode,
      getClassBySimulationMode,
      calculatePercentageChangeInPrice,
      formatMoney: (number) => formatCurrencyOrDash(number, currencyFormat.value),
    };
  },
};
</script>
<style lang="scss" scoped>
.simulated-price {
  display: flex;
  gap: 0.25rem;
  align-items: center;

  .underline {
    text-decoration: underline;
  }

  &:hover {
    .editable-price {
      text-decoration: underline;
    }
  }
}
.editable-price-container {
  display: flex;
  align-items: center;
  .editable-price {
    padding-inline-start: 0.25rem;
    &:hover {
      text-decoration: underline;
    }
  }
}
.up {
  transform: rotate(0);
}
.down {
  transform: rotate(180deg);
}
.right {
  transform: rotate(90deg);
}
.left {
  transform: rotate(270deg);
}
</style>
