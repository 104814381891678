<template>
  <div
    class="purchase-list-status"
    :class="[purchaseListStatus]"
    @mouseover="isHovering = true"
    @mouseleave="isHovering = false"
  >
    <component :is="purchaseListStatusIcon" :size="16" />
    <div v-if="isHovering" class="purchase-list-status-chooser">
      <el-tooltip
        popper-class="purchase-list-tooltip"
        placement="top"
        :content="$t(`terms.supplierTerms.supplierTermTable.${PURCHASE_LIST_STATUS.DISABLED}`)"
      >
        <div
          class="purchase-list-status-icon"
          :class="{ active: purchaseListStatus === PURCHASE_LIST_STATUS.DISABLED }"
          @click.stop="onChoosePurchaseListStatus(PURCHASE_LIST_STATUS.DISABLED)"
        >
          <DisabledIcon class="icon" :size="16" />
        </div>
      </el-tooltip>

      <el-tooltip
        popper-class="purchase-list-tooltip"
        placement="top"
        :content="$t(`terms.supplierTerms.supplierTermTable.${PURCHASE_LIST_STATUS.NEUTRAL}`)"
      >
        <div
          class="purchase-list-status-icon"
          :class="{ active: purchaseListStatus === PURCHASE_LIST_STATUS.NEUTRAL }"
          @click.stop="onChoosePurchaseListStatus(PURCHASE_LIST_STATUS.NEUTRAL)"
        >
          <NeutralIcon class="icon" :size="16" />
        </div>
      </el-tooltip>
      <el-tooltip
        popper-class="purchase-list-tooltip"
        placement="top"
        :content="$t(`terms.supplierTerms.supplierTermTable.${PURCHASE_LIST_STATUS.APPROVED}`)"
      >
        <div
          class="purchase-list-status-icon"
          :class="{ active: purchaseListStatus === PURCHASE_LIST_STATUS.APPROVED }"
          @click.stop="onChoosePurchaseListStatus(PURCHASE_LIST_STATUS.APPROVED)"
        >
          <ListCheckIcon class="icon" :size="16" />
        </div>
      </el-tooltip>
    </div>
    <p v-else :class="[purchaseListStatus]">
      {{ $t(`terms.supplierTerms.supplierTermTable.${purchaseListStatus}`) }}
    </p>
  </div>
</template>
<script>
import { computed, ref } from 'vue';
import { ListCheckIcon, DisabledIcon, NeutralIcon } from '@/assets/icons';

export const PURCHASE_LIST_STATUS = {
  NEUTRAL: 'neutral',
  APPROVED: 'approved',
  DISABLED: 'disabledStatus',
};

export const getIconByIsRecommended = (isRecommended) => {
  if (isRecommended === true) return 'ListCheckIcon';
  if (isRecommended === false) return 'DisabledIcon';
  return 'NeutralIcon';
};

export const getPurchaseListStatusByIsRecommended = (isRecommended) => {
  if (isRecommended === true) return PURCHASE_LIST_STATUS.APPROVED;
  if (isRecommended === false) return PURCHASE_LIST_STATUS.DISABLED;
  return PURCHASE_LIST_STATUS.NEUTRAL;
};

export const getIsRecommendedByPurchaseListStatus = (purchaseListStatus) => {
  if (purchaseListStatus === PURCHASE_LIST_STATUS.APPROVED) return true;
  else if (purchaseListStatus === PURCHASE_LIST_STATUS.DISABLED) return false;
  else return null;
};

export default {
  name: 'PurchaseListCell',
  components: { DisabledIcon, NeutralIcon, ListCheckIcon },
  props: {
    rowData: { type: Object, default: null },
  },
  emits: ['on-change-purchase-list-status'],
  setup(props, { emit }) {
    const isHovering = ref(false);
    const isRecommended = computed(() => props.rowData?.isRecommended);

    const purchaseListStatusIcon = computed(() => getIconByIsRecommended(isRecommended.value));
    const purchaseListStatus = computed(() => getPurchaseListStatusByIsRecommended(isRecommended.value));

    const onChoosePurchaseListStatus = (choice) => {
      if (choice !== purchaseListStatus.value) emit('on-change-purchase-list-status', choice);
    };

    return {
      PURCHASE_LIST_STATUS,
      isHovering,
      isRecommended,
      purchaseListStatus,
      purchaseListStatusIcon,
      onChoosePurchaseListStatus,
    };
  },
};
</script>
<style lang="scss">
@import '@/stylesheets/scss/global';
.status-tooltip {
  padding: 0.25rem !important;
}
.purchase-list-status {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  height: 1rem;

  p.approved,
  p.disabledStatus {
    color: $typography-primary;
  }
}
.approved {
  color: #11874f;
}
.disabledStatus {
  color: #e52044;
}
.neutral {
  color: $typography-secondary;
}
.purchase-list-status-chooser {
  display: flex;
  border-radius: 0.25rem;
  border: 1px solid $outline;
  background: $white;
  padding: 0.25rem;
  text-align: center;
  align-items: center;

  .active {
    background-color: $outline;
    .icon {
      color: $typography-primary !important;
    }
  }

  .purchase-list-status-icon {
    width: 24px;
    border-radius: 0.25rem;
    &:hover {
      cursor: pointer;
      background-color: $secondary;
    }
    .icon {
      margin-bottom: 1px;
      color: $typography-secondary;
    }
  }
}
.purchase-list-tooltip {
  &.el-tooltip__popper {
    padding: 5px;
  }
}
</style>
