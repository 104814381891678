<template>
  <TruncatedText
    v-if="rowData !== null && rowData.product !== null && rowData.product.sku !== null"
    :name="`product-sku-${rowData.product && rowData.product.sku}`"
    :style="{ width: '100%', direction: 'ltr' }"
  >
    {{ rowData.product && rowData.product.sku }}
  </TruncatedText>
  <p v-else>{{ MISSING_DATA_TEXT }}</p>
</template>
<script>
import { TruncatedText } from '@/modules/core/components';
import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';

export default {
  name: 'SkuCell',
  components: { TruncatedText },
  props: {
    rowData: { type: Object, default: null },
  },
  setup() {
    return { MISSING_DATA_TEXT };
  },
};
</script>
<style lang="scss" scoped></style>
